<template>
  <v-dialog v-model="dialog" width="900">
    <v-card>
      <v-card-title>Account Modal</v-card-title>
      <v-card-text>
        <div class="d-flex">
          <h2>{{ item.name }}</h2>
          <p
            :class="
              item.is_activated == 1 ? 'success--text ml-3' : 'error--text ml-3'
            "
          >
            {{ item.is_activated == 1 ? "Activated" : "Disabled" }}
          </p>
          <v-spacer></v-spacer>
          <v-btn icon color="orange" @click="enableEditModal = true"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th>Seal</th>
              <th>Secret Question</th>
              <th>Secret Answer</th>
              <th>Number of Filed Cases</th>
              <th>Activation Date</th>
              <th>Expiration Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-avatar v-if="item.seal_url != null">
                  <img :src="item.seal_url" alt="Seal URL" />
                </v-avatar>
                <v-avatar v-else class="error--text">No Picture</v-avatar>
              </td>
              <td>{{ item.secret_question }}</td>
              <td>{{ item.secret_answer }}</td>
              <td>{{ item.complaints_count }}</td>
              <td>{{ item.activation_date }}</td>
              <td>{{ item.expiration_date }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <p class="mt-4">Log in Information</p>
        <v-data-table
          class="mt-n4"
          width="400"
          :items="item.user_log_ins"
          :headers="headers"
        ></v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('close')">Close</v-btn>
        <v-spacer></v-spacer>
        <p class="caption">accountDialogModal</p>
      </v-card-actions>
    </v-card>
    <!-- EDIT MODAL -->
    <v-dialog v-model="enableEditModal" width="400">
      <v-card>
        <v-card-title>Edit Account</v-card-title>
        <v-card-text>
          <div class="d-flex justify-center">
            <p class="mt-4 mr-2 font-weight-bold">Activation Date</p>
            <input type="date" v-model="item.activation_date" />
          </div>
          <div class="d-flex justify-center">
            <p class="mt-4 mr-2 font-weight-bold">Expiration Date</p>
            <input type="date" v-model="item.expiration_date" />
          </div>

          <div class="d-flex">
            <p class="mt-4 mr-2 font-weight-bold">Change Status</p>
            <v-select
              :items="statusArray"
              item-value="value"
              item-text="text"
              v-model="item.is_activated"
            ></v-select>
          </div>
          <v-text-field
            label="Change Password"
            v-model="newPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :loading="loading" @click="submit()"
            >Submit</v-btn
          >
          <v-btn @click="enableEditModal = false">Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption">accountDialogModal</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      newPassword: "",
      enableEditModal: false,
      dialog: true,
      loading: false,
      statusArray: [
        { text: "Activate", value: 1 },
        { text: "Deactivate", value: 0 },
      ],
      headers: [
        { text: "id", value: "id" },
        { text: "Date", value: "created_at" },
      ],
    };
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
    }),
    submit() {
      this.loading = true;

      if (this.newPassword != "") {
        axios
          .put("changeAccountPassword/" + this.newPassword + "/" + this.item.id)
          .then(() => {
            this.set_notification({
              message: "You have successfully updated password",
              color: "success",
              showing: true,
              icon: "mdi-check",
            });
          });
      } else {
        axios.put("editStatus", this.item).then(() => {
          this.set_notification({
            message: "You have successfully edited an account",
            color: "success",
            showing: true,
            icon: "mdi-check",
          });
        });
      }

      return (this.loading = false);
    },
  },
};
</script>

<style></style>
